import React from 'react';
import BioContainer from './BioContainer';

const BioVladka: React.FC = () => (
  <BioContainer
    name="Vlaďka"
    photoName="vladka_dalibor.jpg"
  >
    <BioContainer.CS>
      Mamka od Zuzky vždycky chtěla pomáhat lidem, a tak se vydala na dráhu fyzioterapeutky. Už jako malá hodně sportovala - jezdila závodně na běžkách. Na základní škole
      ve Frenštátě s ní do vedlejší třídy chodila Iveta Bartošová, o které tvrdila, že “jí to nešlo na běžkách a tak začala raději zpívat”. Své sportovní mládí později
      ráda používala jako zbraň na své líné pubertální děti: “Co jste zase dělali celý den? To já jsem si ve vašem věku vyběhla na Javorník a zpátky než moje mamka přišla
      z práce!” Dnes je to hrdá dvojnásobná babička, která se nebojí ničeho kromě hadů a miluje turistiku, cestování, ABBU a biatlon.
    </BioContainer.CS>

    <BioContainer.EN>
      Zuzka’s mother always wanted to help people and so she chose the path of physiotherapy practitioner. She has done sports since she was a child - mainly
      competitive cross country skiing. She went to school with Iveta Bartošová about whom she said that “she couldn’t ski so she went for singing instead”. She often
      used her sports filled childhood as a weapon towards her lazy children “And what have you been doing all day? At your age I ran up and down Javorník before my mom
      returned from work!” Now she is a proud grandmother not afraid of anything except snakes. She loves hiking, traveling, ABBA and biathlon.
    </BioContainer.EN>
  </BioContainer>
);

export default BioVladka;
