import React from 'react';
import BioContainer from './BioContainer';

const BioCheddar: React.FC = () => (
  <BioContainer
    name="Honza 'Čáďa'"
    photoName="cheddar.jpg"
  >
    <BioContainer.CS>
      Čáďa aka Cheddar je další z řady Honzů, kterým se neříká Honza. Člověk, který když vejde do místnosti, tak se všem přítomným raketovým tempem zvedá nálada. Jeho
      hlasitý smích je extrémně nakažlivý a humor nekonečný. Čáďa je slušný člověk, chatař, fanoušek bagrů a nově vybudované světelné křižovatky na Petřinách. Má
      australského ovčáka Dobbyho a nejvíc nadšený bude, když se ho zeptáte, jestli jeho pes už dostal ponožku.
    </BioContainer.CS>

    <BioContainer.EN>
      Čaďa aka Cheddar is another Honza here who is not called Honza. A person who, when he walks into a room, raises the mood of everyone present at a rocket pace.
      His loud laugh is extremely contagious and his humor endless. Čáďa is a decent person, a cottager, a fan of excavators and the newly built traffic lights in
      Petřiny. He has an Australian Shepherd, Dobby, and will be most excited when you ask him if his dog got a sock yet.
    </BioContainer.EN>
  </BioContainer>
);

export default BioCheddar;
