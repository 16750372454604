import React from 'react';
import BioContainer from './BioContainer';

const BioDalibor: React.FC = () => (
  <BioContainer
    name="Dalibor"
    photoName="vladka_dalibor.jpg"
  >
    <BioContainer.CS>
      Taťka od Zuzky je vášnivý kutil, který umí v domácnosti opravit a vylepšit opravdu vše. Jedinou nevýhodou je množství prostoru, který ke svému kutilství potřebuje.
      Naštěstí jejich děti už před nějakou dobou uvolnili kvartýr a jejich pokoje se tak mohly stát plnohodnotnou exklávou garáže. Posledních pár let se věnuje 3D tisku
      všemožných zlepšováků a vymožeností. Kromě kutilství rád jezdí na kole, lyžuje, staví modely letadel a lodí, cestuje a věnuje se vnoučatům. Jeho specialitou je
      kotlíkový guláš.
    </BioContainer.CS>

    <BioContainer.EN>
      Zuzka’s father is a passionate handyman and can improve and fix anything in the household. The only downside to his DIY passion is the extreme amount of space
      that is needed for his projects. Fortunately, his kids already packed up and left the house so now their rooms can serve a higher purpose as an extension of the
      garage. For the last couple of years he tinkers with 3D printing of all sorts of gadgets. Apart from DIY tinkering he enjoys biking, skiing, building model planes
      and ships, traveling and his grandchildren. His specialty is “kotlíkový guláš”.
    </BioContainer.EN>
  </BioContainer>
);

export default BioDalibor;
