import React from 'react';
import BioContainer from './BioContainer';

const BioAnet: React.FC = () => (
  <BioContainer
    name="Anet"
    photoName="honza_anet.jpg"
  >
    <BioContainer.CS>
      Anet je snoubenka od Michalova bratra Honzy. Hrála sportovně florbal, ale teď už raději chodí po horách. S Honzou se znají už přes 20 let, ale jejich cesty se
      protnuly až v létě 2020. I když říká, že má “jen ráda pořádek”, Honza o ní tvrdí, že je posedlá úklidem. Trpí OCD na cokoliv, co Honza umístí na jiné místo, než kam
      to patří. Mimo vaření a starání se o své blízké miluje chození na nejrůznější koncerty. Její vysněná práce je být učitelkou nebo interiérovou designérkou. Se svými
      dvěma syny (Maty 16, David 12) a Honzou žijí spolu v Rožnově.
    </BioContainer.CS>

    <BioContainer.EN>
      Anet is the fiancee of Michal's brother Honza. She played competitive floorball, but now she prefers to hike in the Beskydy mountains. She and Honza have known each
      other for over 20 years, but their paths crossed only recently - in the summer of 2020. Although she says that she "just likes her home tidy", Honza claims that she
      is obsessed with cleaning. He suffers from OCD about anything that Honza misplaces by accident. Apart from cooking and taking care of her loved ones, she loves
      going to various concerts. Her dream job is to be a teacher or an interior designer. They live together in Rožnov with her two sons (Maty 16, David 12).
    </BioContainer.EN>
  </BioContainer>
);

export default BioAnet;
