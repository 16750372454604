import React from 'react';
import css from './Homepage.module.scss';

import BioRoman from './Bio/BioRoman';
import BioVladka from './Bio/BioVladka';
import BioDalibor from './Bio/BioDalibor';
import BioMasa from './Bio/BioMasa';
import BioPavel from './Bio/BioPavel';
import BioBara from './Bio/BioBara';
import BioFilip from './Bio/BioFilip';
import BioPetrik from './Bio/BioPetrik';
import BioMatyas from './Bio/BioMatyas';
import BioZuzka from './Bio/BioZuzka';
import BioPeta from './Bio/BioPeta';
import BioCheddar from './Bio/BioCheddar';
import BioLubos from './Bio/BioLubos';
import BioNikol from './Bio/BioNikol';
import BioKatka from './Bio/BioKatka';
import BioSarka from './Bio/BioSarka';
import BioJindra from './Bio/BioJindra';
import BioVita from './Bio/BioVita';
import BioLucka from './Bio/BioLucka';
import BioLuky from './Bio/BioLuky';
import BioSam from './Bio/BioSam';
import BioMarek from './Bio/BioMarek';
import BioHonza from './Bio/BioHonza';
import BioAnet from './Bio/BioAnet';
import BioSabina from './Bio/BioSabina';
import BioDavid from './Bio/BioDavid';
import BioHonzaSr from './Bio/BioHonzaSr';
import BioMaruska from './Bio/BioMaruska';
import BioLibor from './Bio/BioLibor';
import BioErcaUba from './Bio/BioErcaUba';
import BioPetaVasku from './Bio/BioPetaVasku';
import BioAndy from './Bio/BioAndy';
import BioKocos from './Bio/BioKocos';
import BioKuba from './Bio/BioKuba';
import BioAlistairVeronika from './Bio/BioAlistairVeronika';
import BioStepanJana from './Bio/BioStepanJana';
import BioMartin from './Bio/BioMartin';
import BioPavelSarka from './Bio/BioPavelSarka';
import BioStepanFarhia from './Bio/BioStepanFarhia';
import BioCimbalAldamas from './Bio/BioCimbalAldamas';

const App: React.FC = () => (
  <>
    <BioRoman />
    <hr className={css.clear} />
    <BioVladka />
    <hr className={css.clear} />
    <BioDalibor />
    <hr className={css.clear} />
    <BioPeta />
    <hr className={css.clear} />
    <BioZuzka />
    <hr className={css.clear} />
    <BioPetrik />
    <hr className={css.clear} />
    <BioMatyas />
    <hr className={css.clear} />
    <BioMaruska />
    <hr className={css.clear} />
    <BioHonzaSr />
    <hr className={css.clear} />
    <BioLibor />
    <hr className={css.clear} />
    <BioHonza />
    <hr className={css.clear} />
    <BioAnet />
    <hr className={css.clear} />
    <BioDavid />
    <hr className={css.clear} />
    <BioSabina />
    <hr className={css.clear} />
    <BioMarek />
    <hr className={css.clear} />
    <BioMasa />
    <hr className={css.clear} />
    <BioPavel />
    <hr className={css.clear} />
    <BioBara />
    <hr className={css.clear} />
    <BioFilip />
    <hr className={css.clear} />
    <BioCheddar />
    <hr className={css.clear} />
    <BioLubos />
    <hr className={css.clear} />
    <BioNikol />
    <hr className={css.clear} />
    <BioKatka />
    <hr className={css.clear} />
    <BioSarka />
    <hr className={css.clear} />
    <BioJindra />
    <hr className={css.clear} />
    <BioVita />
    <hr className={css.clear} />
    <BioLucka />
    <hr className={css.clear} />
    <BioLuky />
    <hr className={css.clear} />
    <BioSam />
    <hr className={css.clear} />
    <BioErcaUba />
    <hr className={css.clear} />
    <BioAlistairVeronika />
    <hr className={css.clear} />
    <BioStepanJana />
    <hr className={css.clear} />
    <BioMartin />
    <hr className={css.clear} />
    <BioPavelSarka />
    <hr className={css.clear} />
    <BioPetaVasku />
    <hr className={css.clear} />
    <BioAndy />
    <hr className={css.clear} />
    <BioKocos />
    <hr className={css.clear} />
    <BioKuba />
    <hr className={css.clear} />
    <BioStepanFarhia />
    <hr className={css.clear} />
    <BioCimbalAldamas />
    <hr className={css.clear} />
  </>
);

export default App;
