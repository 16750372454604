import React from 'react';
import BioContainer from './BioContainer';

const BioKocos: React.FC = () => (
  <BioContainer
    name="Honza 'Kocoš'"
    photoName="kocos.jpg"
  >
    <BioContainer.CS>
      Honza Kocián, kterému se ve škole nikdy neříkalo jinak než Kocoš, je Zuzčin spolužák ze střední, který se jako jeden z mála po maturitě vydal na VŠE do Prahy,
      ačkoli většina spolužáků zamířila do Brna. Přesto s námi neztratil kontakt - ba naopak dodnes patří k nejaktivnějšímu jádru naší Rožnovské party. Honza se věnuje
      IT, pracuje na volné noze. Ale jelikož to není typický introvertní “ajťák”, k práci využívá coworkingová místa, kde si se svou společenskou a přátelskou povahou
      jednoduše nachází nové přátele a parťáky k cestování.
    </BioContainer.CS>

    <BioContainer.EN>
      Honza Kocián, who was never called anything other than Kocoš at school, is Zuzka's classmate from high school, who was one of the few to go to VŠE in Prague
      after graduation, although most of his classmates went to Brno. Nevertheless, he did not lose contact with us - on the contrary, he still belongs to the most
      active core member of our Rožnov group. Honza works as an IT freelancer but since he is not a typical introvert, he uses coworking places to work, where he easily
      finds new friends and travel partners with his sociable and friendly nature.
    </BioContainer.EN>
  </BioContainer>
);

export default BioKocos;
