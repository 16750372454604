import React from 'react';
import BioContainer from './BioContainer';

const BioFilip: React.FC = () => (
  <BioContainer
    name="Filip"
    photoName="bara_filip.jpg"
  >
    <BioContainer.CS>
      Neexistuje na světě člověk, který by neměl rád Filipa. Je extrémně chytrý, skvěle vypráví vtipy (chtějte po něm tu o nasraném prasátku!), umí hrát snad na všechny
      hudební nástroje a když se opije, vypadá jako medvídek. Má rád cestování, podcasty, pivo, přátele, kapelu MIDI LIDI a hlavně Báru. Pracuje už několik let v Evropské
      komisi.
    </BioContainer.CS>

    <BioContainer.EN>
      There isn't a person in the world who doesn't like Filip. He's extremely smart, tells great jokes (ask him about the pissed pig!), can play just about every
      musical instrument, and looks like a teddy bear when he gets drunk. He likes traveling, podcasts, beer, friends, the band MIDI LIDI, but most of all his wife
      Bára. He has been working at the European Commission for several years.
    </BioContainer.EN>
  </BioContainer>
);

export default BioFilip;
