import React from 'react';
import BioContainer from './BioContainer';

const BioAndy: React.FC = () => (
  <BioContainer
    name="Andy"
    photoName="peta_andy.jpg"
  >
    <BioContainer.CS>
      Soutěž o nejoriginálnější fotky vyhrává Peťa s Andy. A to právě díky Andy, kterou už mnoho let fascinuje fotka a jejíž sociální sítě jsou jednímvelkým
      uměleckýmdílem. Tato půvabná bytost s havraními vlasy okouzlila Peťu během studií na vysoké škole v Brně. Vzali se letos v září. Andy kromě fotky a svého
      novomanžela milujetaké kulturu, cestování a kočky.
    </BioContainer.CS>

    <BioContainer.EN>
      The competition for the most original photos is won by Peťa and Andy. And that's thanks to Andy, who has been fascinated by photography for many years and whose
      social networks are one big work of art. This charming creature with raven hair enchanted Peťa during their studies at the university in Brno. They got married
      this September. In addition to photography and her new husband, Andy also loves culture, travel and cats.
    </BioContainer.EN>
  </BioContainer>
);

export default BioAndy;
