import React from 'react';
import BioContainer from './BioContainer';

const BioLubos: React.FC = () => (
  <BioContainer
    name="Luboš 'Candy'"
    photoName="candy_nikol.jpg"
  >
    <BioContainer.CS>
      Luboš aka Candymane je Zuzčin kolega. Sdílí spolu kancelář, smysl pro humor, obědy a hlavně firmu. Poznali se za hlubokého Covidu, kdy spolu začali natáčet pořad
      Mr. Kubelík show. Projekt se po čase rozpadl, ale oni si padli do noty a zůstali spolu. Založili firmu Wolfstreetbros a věnují se videoprodukci. Candy je rozený
      bavič, skvělý vypravěč a bývalý rapper, který si tyká s půlkou Prahy. Luboš umí žít život, miluje dobré jídlo a před rokem se stal otcem dcerky Miládky.
    </BioContainer.CS>

    <BioContainer.EN>
      Luboš aka Candymane is Zuzka's colleague. They share an office, a sense of humor, lunches and most importantly a company. They got to know each other during
      Covid, when they started filming the Mr. Kubelík Show. The project fell apart after a while, but they hit it off and stayed together. They founded the company
      Wolfstreetbros and are dedicated to video production. Candy is a born entertainer, a great storyteller and a former rapper who knows half of Prague on a first
      name basis. Luboš knows how to live life, he loves good food and a year ago he became the father of his daughter, Miládka (the future rapper Milli B).
    </BioContainer.EN>
  </BioContainer>
);

export default BioLubos;
