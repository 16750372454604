import React from 'react';
import BioContainer from './BioContainer';

const BioStepanJana: React.FC = () => (
  <BioContainer
    name="Štěpán + Jana"
    photoName="stepan_jana.jpg"
  >
    <BioContainer.CS>
      Štěpán je dalším z dlouholetých Michalových kolegů a dobrých kamarádů. Poprvé se potkali společně s Kubou a Alistairem na projektu v ČSOB. Má podobně ztřeštěný
      humor jako Michal a jeho bláznivý smích je neuvěřitelně nakažlivý a těžko přeslechnutelný. Štěpán tak trochu balancuje na hraně geniality a demence :) Celá jeho
      rodina jsou členové Mensy (sdružení lidí s IQ vyšším než 130), on si dle svých slov IQ test raději nedělá. V mládí se věnoval sportovně cyklokrosu, který musel
      kvůli nehodě opustit. Ve volném čase je vynálezcem nevšedních věcí a advokátem zdravé výživy. Se svou přítelkyní Janou staví dům a vychovávají společně čtyři děti.
    </BioContainer.CS>

    <BioContainer.EN>
      Štěpán is another of Michal's longtime colleagues and good friends. They first met on a project at ČSOB, together with Kuba and Alistair. He has a similar weird
      sense of humor as Michal and his crazy laugh is incredibly contagious and hard to overhear. Štěpán is kind of balancing on the edge of genius and dementia :) His
      whole family are members of Mensa (an association of people with an IQ higher than 130) and according to him, that’s the reason he avoids taking an IQ test. In
      his youth, he did competitive cyclocross, which he had to leave due to an accident. In his free time, he is an inventor of all sorts of technical gadgets and
      improvements and an advocate of healthy nutrition. He and his girlfriend Jana raise four children together and are enjoying the pain of house reconstruction.
    </BioContainer.EN>
  </BioContainer>
);

export default BioStepanJana;
