import React from 'react';
import BioContainer from './BioContainer';

const BioAlistairVeronika: React.FC = () => (
  <BioContainer
    name="Alistair + Veronika"
    photoName="alistair_veronika.jpeg"
  >
    <BioContainer.CS>
      S Alistairem se Michal potkal na projektu v Bratislavě a následně je jejich pracovní cesty zavedly do Prahy, zpět do Bratislavy a Londýna. Strávili spolu při práci
      na projektech spoustu času – hlavně v Bratislavě a Londýně, kde spolu bydleli ve stejném apartmánu. Během jejich cest představil Michalovi spoustu skvělých seriálů
      a kouzlo Gin&Tonicu. Alistair je skvělým manažerem a posledních pár let se věnuje svým vlastním projektům. Se svou ženou Veronikou mají dva syny. Zuzka i Michal jim
      byli před téměř 10 lety na svatbě.
    </BioContainer.CS>
    <BioContainer.EN>
      Michal met Alistair on a project in Bratislava and subsequently their work journey took them to Prague, back to Bratislava and London. They spent a lot of time
      together working on projects - mainly in Bratislava and London, where they lived together in the same apartment. During their travels, he introduced Michal to a
      lot of great series and the magic of Gin & Tonic. Alistair is a great manager and has been working on his own projects for the past couple of years. He has two
      sons with his wife Veronika. Zuzka and Michal were at their wedding almost 10 years ago.
    </BioContainer.EN>
  </BioContainer>
);

export default BioAlistairVeronika;
