import React from 'react';
import BioContainer from './BioContainer';

const BioMasa: React.FC = () => (
  <BioContainer
    name="Máša"
    photoName="masa_pavel.jpg"
  >
    <BioContainer.CS>
      Svědkyně se Zuzkou jsou nerozlučitelné kamarádky už od útlého dětství. Nezabránil tomu ani fakt, že je každá z jiného města. Psaly si ručně dopisy a v neděli večer,
      když byl levný tarif, tak spolu celé hodiny mluvily přes pevnou linku. Dokonce spolu v 10 a 11ti letech jely samy bez rodičů na zájezd do Chorvatska. Pro obě byl
      osudný rok 2012 - Máša se dala dohromady s Pavlem a Zuzka s Michalem. Máša už je rok vdanou paní a má krásnou dcerku Báru.
    </BioContainer.CS>

    <BioContainer.EN>
      The maid of honor Máša and Zuzka have been inseparable friends since childhood. The fact that each of them is from a different city did not prevent it either.
      They wrote letters to each other by hand and on Sunday evenings, when the phone tariff was cheap, they talked to each other for hours on a landline. At the age of
      10 and 11, they even went on a trip to Croatia alone without their parents. 2012 was a fateful year for both of them - Máša got together with Pavel and Zuzka with
      Michal. Maša married Pavel a year ago and they have a beautiful daughter Bára.
    </BioContainer.EN>
  </BioContainer>
);

export default BioMasa;
