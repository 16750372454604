import React from 'react';
import BioContainer from './BioContainer';

const BioSam: React.FC = () => (
  <BioContainer
    name="Sam"
    photoName="sam.jpg"
  >
    <BioContainer.CS>
      Pokud jste někdy chtěli naživo potkat pravého britského gentlemana, nemáte zač. Sam na této svatbě jednoznačně vyhrává cenu nejvzdálenějšího účastníka - přiletěl až
      z daleké Argentiny, kde momentálně studuje. Pochází ale z Walesu, se Zuzkou se potkali během letní práce v Londýně. Sam je nejhodnější člověk na světě, kterého
      nikdy neuvidíte ve špatné náladě. Pokud si chcete procvičit angličtinu, pozvěte ho na bar!
    </BioContainer.CS>

    <BioContainer.EN>
      If you've ever wanted to meet a true British gentleman in person, look no further. Sam clearly wins the farthest attendee award at this wedding - he flew all the
      way from Argentina, where he is currently studying. He is from Wales and met Zuzka during a summer job in London. Sam is the nicest person in the world who you
      will never see in a bad mood. If you want to practice your English, invite him to the bar!
    </BioContainer.EN>
  </BioContainer>
);

export default BioSam;
