import React from 'react';
import BioContainer from './BioContainer';

const BioHonza: React.FC = () => (
  <BioContainer
    name="Honza"
    photoName="honza_anet.jpg"
  >
    <BioContainer.CS>
      O Honzovi si všichni myslí, že je starší bráchou Michala. Jenže je o dva roky mladší. Celé dětství mu to vadilo, ale mamka ho uklidňovala faktem, že má přece dříve
      narozeniny (on v dubnu, Michal v květnu). Jako mladší brácha si tak kompenzoval svůj věk tím, že se snažil být ve všem lepší. Samozřejmě dělali mámě neustále doma
      radost tím, že se hádali o věci, naháněli a prali. V dospělosti si k sobě ale našli cestu a jsou si blíž, než kdykoliv dřív. Honza si vždycky rád najde čas na
      chytání ryb, cestování, kamarády a rodinu. Ve společnosti je vždycky oblíbený a středem pozornosti. Má syna Davida a momentálně žije se svou snoubenkou Anet a
      jejími syny v Rožnově.
    </BioContainer.CS>

    <BioContainer.EN>
      Everyone thinks that Honza is Michal's older brother. But he is two years younger. It bothered him when he was a kid, but his mother reassured him with the fact
      that his birthday was earlier (his in April, Michal’s in May). As a younger brother, he compensated for his age by trying to be better at everything. Of course,
      they always made their mom very happy by constantly arguing about things, chasing around the flat and fighting. As they grew up, however, they finally set aside
      their rivalry and are closer than ever before. Honza always likes to find time for fishing, traveling, friends and family. Honza always likes to find time for
      fishing, traveling, friends and family. He has a son, David, and currently lives with his fiancee Anet and her sons in Rožnov.
    </BioContainer.EN>
  </BioContainer>
);

export default BioHonza;
