import React from 'react';
import BioContainer from './BioContainer';

const BioSabina: React.FC = () => (
  <BioContainer
    name="Sabina"
    photoName="sabina_david.jpg"
  >
    <BioContainer.CS>
      Sabina je mámou Honzova syna Davida. S Honzou se potkali na brigádě v Londýně, ale jejich vztah nakonec překonal hranice a narodil se David. I když jim nakonec
      společný život nepřál, zůstávají nadále skvělými přáteli a rodiči. Sabina je jeden z nejhodnějších lidí, které známe. Svoji lásku k dětem a angličtině přetavila v
      profesionální jazykovou výuku pro děti a je v Chorvatsku na roztrhání. Momentálně žije s Davidem v Zagrebu, ale každý den si volají přes videohovory s babičkou
      Maruškou.
    </BioContainer.CS>

    <BioContainer.EN>
      Sabina is the mother of Honza's son David. She and Honza met on a temporary job in London, but their relationship eventually grew and David was born. Even though
      their life together didn't work out, they continue to be great friends and parents. Sabina is one of the nicest people we know. She turned her love for children
      and English into a professional career as a language teacher for children and is booked out in Croatia. She currently lives with David in Zagreb, but they call
      their grandmother Maruška via video calls every day.
    </BioContainer.EN>
  </BioContainer>
);

export default BioSabina;
