import React from 'react';
import BioContainer from './BioContainer';

const BioNikol: React.FC = () => (
  <BioContainer
    name="Nikol"
    photoName="candy_nikol.jpg"
  >
    <BioContainer.CS>
      Rodačka ze Žďáru nad Sázavou se skvělým smyslem pro humor, pronikavým smíchem a společenskou náturou na Luboše zapůsobila tak intenzivně, že po třech měsících
      známosti už spolu čekali dceru Miládku a bylo vymalováno. Nikol, které Luban neřekne jinak než “babymama”, se před mateřstvím intenzivně věnovala make-upu,
      pracovala například ve známém řetězci Sephora.Trpí velice specifickou profesní deformací - stačí kolem ní projít a ona ví, co na sobě máte za parfém.
    </BioContainer.CS>

    <BioContainer.EN>
      The native of Žďár nad Sázavou, made such an intense impression on Luboš with her great sense of humor, piercing laugh and social nature, that after three months
      of dating, they were already expecting their daughter Miládka. Before becoming a mother, Nikol, whom Luban calls nothing but "babymama", was intensively involved
      in make-up, for example, she worked at the well-known Sephora chain. She suffers from a very specific professional deformity - just walk past her and she knows
      what perfume you are wearing.
    </BioContainer.EN>
  </BioContainer>
);

export default BioNikol;
