import React from 'react';
import BioContainer from './BioContainer';

const BioMartin: React.FC = () => (
  <BioContainer
    name="Martin"
    photoName="martin.jpg"
  >
    <BioContainer.CS>
      Martina zná Michal už z dob svého ztraceného mládí, kdy trávili snad veškerý svůj volný čas v počítačových kavárnách. Mimo hraní her spolu také dělali pár let
      obsluhu v Rožnovském “Planetu” - v té době byli svědky shoření rožnovské tržnice u parku (Michal je dokonce vidět na hasičské fotce z hašení požáru). K
      nezapomenutelným akcím patřily také sobotní grilovačky s přáteli, které často končily až další den s kocovinou a léčením v sauně místního krytého bazénu. Martin se
      posledních několik let věnoval propagaci různých eSports událostí a teď pracuje jako komunitní manažer pro Wargaming.
    </BioContainer.CS>

    <BioContainer.EN>
      Michal has known Martin since the days of his lost youth, when they spent almost all their free time in computer cafes. In addition to playing games, they also
      worked together for a few years in Rožnov “Planet” internet cafe&bar. At that time they witnessed the burning of the Rožnov flea market near the park (Michal can
      even be seen in the firefighter's photo). They also enjoyed their regular Saturday barbecues with friends, which often ended with a hangover at a local indoor
      swimming sauna. Martin has spent the last few years promoting various eSports events and now works as a community manager for Wargaming.
    </BioContainer.EN>
  </BioContainer>
);

export default BioMartin;
