import React from 'react';
import BioContainer from './BioContainer';

const BioJindra: React.FC = () => (
  <BioContainer
    name="Jindra"
    photoName="sarka_jindra.jpg"
  >
    <BioContainer.CS>
      Jindra je spolužák Zuzky z AVT v Opavě, se Šárkou se ale dali dohromady až po studiích. Jindra je talentovaný filmař a kameraman, už během studia natočil svůj
      autorský sci-fi snímek Amber. V nedávné době se podílel například na vzniku filmu Mimořádná událost od režiséra Vlastníků nebo nové krimisérie Stíny v Mlze na ČT.
    </BioContainer.CS>

    <BioContainer.EN>
      Jindra is Zuzka's classmate from AVT in Opava, but he and Šárka got together after their studies. Jindra is a talented filmmaker and cameraman, he already shot
      his original sci-fi film Amber during his studies. Recently, he participated, for example, in the production of the film “Mimořádná událost” by the director of
      “Vlastníci” or the new crime series “Stíny v mlze” on ČT.
    </BioContainer.EN>
  </BioContainer>
);

export default BioJindra;
