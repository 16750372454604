import { useState } from 'react';
import { createContainer } from 'unstated-next';

export type Lang = 'cs' | 'en';

/**
 * Wrapper around React.Context (see https://github.com/jamiebuilds/unstated-next)
 */
const I18nContainer = createContainer(() => {
  const [lang, setLang] = useState<Lang>('cs');

  return {
    lang,
    setLang,
  };
});

export default I18nContainer;
