import React from 'react';
import { ROUTES } from '../core/constants';
import css from './QrCodes.module.scss';

const urls = [
  ROUTES.ROMAN,
  ROUTES.VLADKA,
  ROUTES.DALIBOR,
  ROUTES.MASA,
  ROUTES.PAVEL,
  ROUTES.BARA,
  ROUTES.FILIP,
  ROUTES.PETRIK,
  ROUTES.MATYAS,
  ROUTES.ZUZKA,
  ROUTES.PETA_MAZAC,
  ROUTES.CHEDDAR,
  ROUTES.LUBOS,
  ROUTES.NIKOL,
  ROUTES.KATKA,
  ROUTES.SARKA,
  ROUTES.JINDRA,
  ROUTES.VITA,
  ROUTES.LUCKA,
  ROUTES.LUKY,
  ROUTES.SAM,
  ROUTES.MAREK,
  ROUTES.HONZA,
  ROUTES.ANET,
  ROUTES.SABINA,
  ROUTES.DAVID,
  ROUTES.HONZA_TATA,
  ROUTES.MARUSKA,
  ROUTES.LIBOR,
  ROUTES.ERCA_UBA,
  ROUTES.PETA_VASKU,
  ROUTES.ANDY,
  ROUTES.KOCOS,
  ROUTES.KUBA,
  ROUTES.ALISTAIR_VERONIKA,
  ROUTES.STEPAN_JANA,
  ROUTES.MARTIN,
  ROUTES.PAVEL_SARKA,
  ROUTES.STEPAN_FARHIA,
  ROUTES.CIMBAL_ALDAMAS,
];

type QrProps = {
  url: string
}

const QrCode: React.FC<QrProps> = ({ url }: QrProps) => {
  const qrText = `https://svatba.mzubek.cz${url}`;
  const qrLink = `https://public-api.qr-code-generator.com/v1/create/free?image_format=JPG&image_width=200&background_color=%23FFFFFF&frame_text=Scan+me&marker_bottom_template=version1&marker_left_template=version1&marker_right_template=version1&frame_icon_name=mobile&foreground_color=%23000000&frame_color=%23000000&frame_name=no-frame&qr_code_logo=&qr_code_pattern=rounded-3&qr_code_text=${encodeURIComponent(qrText)}`;
  let filename = `${url.replaceAll('/', '')}.jpeg`;
  return (
    <div className={css.qrCode} key={url}>
      <a
        download={filename}
        href={qrLink}
      >
        <div className={css.url}>{url}</div>
        {/*<img src={qrLink} alt={qrText} />*/}
      </a>
    </div>
  );
};

const QrCodes: React.FC = () => (
  <div>
    <h1> QR kódy k lidem</h1>
    {urls.map(url => (
      <QrCode key={url} url={url} />
    ))}
  </div>
);

export default QrCodes;
