import React from 'react';
import BioContainer from './BioContainer';

const BioMarek: React.FC = () => (
  <BioContainer
    name="Marek"
    photoName="mara.jpg"
  >
    <BioContainer.CS>
      Mára je Michalův nejmladší brácha. Od mala spolu hrávali fotbal, ale narozdíl od Michala u fotbalu zůstal i v dospělosti. Taky spolu sdílejí nadšení pro soutěživé
      počítačové hry, ve kterých Michala už dávno svými schopnostmi překonal. S tátou se do nedávna věnoval stavebním pracím na dřevostavbách, ale teď se snaží rozvíjet
      si vlastní živnost v oblasti stavařství. Se Zuzkou se ale zná taky dlouho - konkrétně spolu chodili do školky a chvíli i na základku. Pracoval také na brigádách v
      Británii a Americe.
    </BioContainer.CS>

    <BioContainer.EN>
      Mára is Michal's youngest brother. They played football together from childhood, but unlike Michal, he continued playing to this day. They also share an
      enthusiasm for competitive gaming, in which he long surpassed Michal’s abilities. Until recently, he and his father were building wooden houses, but now he is
      trying to start his own business in construction. He and Zuzka have known each other for a long time - specifically, they went to kindergarten together and for a
      while to the same elementary school. He also worked in Britain and America.
    </BioContainer.EN>
  </BioContainer>
);

export default BioMarek;
