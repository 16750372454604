import React from 'react';
import BioContainer from './BioContainer';

const BioZuzka: React.FC = () => (
  <BioContainer
    name="Zuzka"
    photoName="mazaci_mladi.jpg"
  >
    <BioContainer.CS>
      Zuzka je švagrová nevěsty a posledních 7 let spolu sdílely stejné jméno. Ode dneška je to ale jediná Zuzana Mazáčová, na kterou tu narazíte. Praktická lékařka ze
      Žďáru nad Sázavou, nejstudovanější z rodu Mazáčů, hrdá dvojnásobná maminka a milovnice dobrého vína, která nevěstu naučila, co to znamená “dekantovat”. Nenechte se
      ale zmást jejím titulem, rozhodně to není uťáplá rezervovaná doktora, naopak - umí to pořádně roztočit.
    </BioContainer.CS>

    <BioContainer.EN>
      Zuzka is the bride's sister-in-law and they have shared the same name (and surname) for the past 7 years. From today on, however, she will be the only Zuzana
      Mazáčová you will meet here. A general practitioner from Žďár nad Sázavou, the most educated of the Mazáč family, a proud mother of two and a lover of good wine,
      who taught the bride what it means to "decant". But don't be fooled by her academic title, she's definitely not a stuffy reserved doctor, on the contrary - she
      can party hard.
    </BioContainer.EN>
  </BioContainer>
);

export default BioZuzka;
