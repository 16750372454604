import React from 'react';
import BioContainer from './BioContainer';

const BioBara: React.FC = () => (
  <BioContainer
    name="Bára"
    photoName="bara_filip.jpg"
  >
    <BioContainer.CS>
      Tato Bára je opravdu Bára, žádná Barbora! Nejvíc nejhodnější člověk na světě, který si v prváku na gymplu přisedl k rebelce Zuzce a zbytek už je historie. Nadaná,
      chytrá a vtipná žena, která po tátovi zdědila cit pro fotografii a po dědovi pro psaní. Vystudovala český jazyk a mediální studia, takže v těchto textech nejspíš
      začne brzy opravovat všechny hrubky. Na konci letošního léta si vzala Filipa, se kterým jsou nerozlučitelná dvojka už od střední a momentálně spolu žijí v Bruselu.
    </BioContainer.CS>

    <BioContainer.EN>
      This Bára is really Bára, no Barbora! The nicest person in the world, who chose a seat next to the rebel Zuzka in the freshman year of high school - and the rest
      is history. A gifted, smart and funny woman who inherited her father's flair for photography and her grandfather's flair for writing. She studied the Czech
      language and media studies, so she will most likely start correcting all the mistakes in these texts as soon as she reads them. At the end of this summer, she
      married Filip, with whom they have been inseparable since high school and currently live together in Brussels.
    </BioContainer.EN>
  </BioContainer>
);

export default BioBara;
