import React from 'react';

import I18nContainer, { Lang } from 'core/I18nContainer';

import css from './BioContainer.module.scss';

type Props = {
  name: string;
  photoName: string;
  children: React.ReactNode;
}

function BioContainer({ name, photoName, children }: Props) {
  const { lang, setLang } = I18nContainer.useContainer();
  const isLangCs = lang === 'cs';

  return (
    <div className={css.container}>
      {photoName && (
        <img className={css.photo} src={`/photos/${photoName}`} alt={'Photo of ' + name} />
      )}
      <h1>
        {name}
        <a
          href="#"
          className={`${css.langToggle} ${isLangCs ? css.flagEn : css.flagCs}`}
          onClick={(e) => {
            setLang(isLangCs ? 'en' : 'cs');
            e.nativeEvent.preventDefault();
          }}
        >
          {isLangCs ? 'EN' : 'CS'}
        </a>
      </h1>

      {children}
    </div>
  );
}

type LangPropInternal = {
  lang: Lang;
  children: React.ReactNode;
}

const BioLang: React.FC<LangPropInternal> = ({ lang, children }: LangPropInternal) => {
  const visible = I18nContainer.useContainer().lang === lang;

  return (
    <div className={`${css.lang} ${visible ? css.langVisible : css.langHidden}`}>
      <p>{children}</p>
    </div>
  );
};

type LangProp = {
  children: React.ReactNode;
}

BioContainer.CS = ({ children }: LangProp) => (
  <BioLang lang="cs">
    {children}
  </BioLang>
);

BioContainer.EN = ({ children }: LangProp) => (
  <BioLang lang="en">
    {children}
  </BioLang>
);

export default BioContainer;
