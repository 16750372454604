import React from 'react';
import BioContainer from './BioContainer';

const BioPavel: React.FC = () => (
  <BioContainer
    name="Pavel"
    photoName="masa_pavel.jpg"
  >
    <BioContainer.CS>
      Pavel s Mášou se seznámili na vysoké škole přes společné kamarády. Pavel je hrdým obyvatelem malé obce Komňátka v Jeseníkách, kde mají jeho rodiče farmu. Mášu ale
      neokouzlil jen tím, že umí podojit krávu, ale hlavně svým sympatickým vzezřením a milou povahou. Pavel přečetl více knížek než kdokoli koho znáte, zná extrémně
      dobře dějiny, geopolitické vztahy a dokáže velmi poutavě vyprávět. Pracuje pro Ministerstvo obrany a momentálně působí v Bruselu v rámci Českého předsednictví v
      Radě EU.
    </BioContainer.CS>

    <BioContainer.EN>
      Pavel and Máša met at university through mutual friends. Pavel is a proud resident of the small village of Komňátka in Jeseníky, where his parents have a farm.
      But he charmed Maša not only with the fact that he can milk a cow, but mainly with his likable appearance and kind personality. Pavel has read more books than
      anyone you know, knows history and geopolitical relations extremely well and is a good storyteller. He works for the Ministry of Defense and currently works in
      Brussels as part of the Czech Presidency of the EU Council.
    </BioContainer.EN>
  </BioContainer>
);

export default BioPavel;
