import React from 'react';
import BioContainer from './BioContainer';

const BioHonzaSr: React.FC = () => (
  <BioContainer
    name="Honza sr."
    photoName="tata_zubek.jpg"
  >
    <BioContainer.CS>
      Táta od Michala je velký cestovatel. Po návštěvě Ameriky ještě za dob hlubokého komunismu se v něm probudil podnikatelský duch a začal v Rožnově na náměstí prodávat
      věci, které v ČR nebyly k sehnání. Potom se ale začal naplno věnovat dřevostavbám podle amerických vzorů. Svůj první dům postavil na Kramolišově, kde do teď žije.
      Své kluky bral už od mala vedl ke sportu (hlavně jízdy na kolech a lyžování). Byl taky průkopníkem českého koloběžkování. Jeho vášeň ke sportu a stavění domů
      bohužel roku 2019 překazila nešťastná nehoda na lyžích, při které si několikanásobně zlomil holenní kost a následujících pár let strávil na vozíku a o berlích. Na
      všechny své syny mluví od mala anglicky a naučil je tak nebát se cizího jazyka.
    </BioContainer.CS>

    <BioContainer.EN>
      Michal's dad is a big traveler. After visiting America during the time of deep communism, his entrepreneurial spirit awakened and he started selling all sorts of
      American gadgets that were not available in the Czech Republic in his shop P&P in Rožnov. But after a while he began to devote himself fully to constructing
      American wooden houses here in Czech republic. He built his first house in Kramolišov, where he still lives. He taught his boys sports from an early age (mainly
      cycling and skiing). He was also a pioneer of Czech scooters. His passion for sports and construction was set back in 2019 by an unfortunate skiing accident in
      which he suffered multiple fractures in his tibia and had to spend the next couple of years in a wheelchair and on crutches. He speaks to all his sons in English
      and has thus taught them not to be afraid of speaking in a foreign language.
    </BioContainer.EN>
  </BioContainer>
);

export default BioHonzaSr;
