import React from 'react';
import BioContainer from './BioContainer';

const BioPeta: React.FC = () => (
  <BioContainer
    name="Peťa 'Mazy'"
    photoName="mazaci_mladi.jpg"
  >
    <BioContainer.CS>
      Peťa, kterému v mládí nikdo neřekl jinak než Mazy, je brácha Zuzky, díky kterému dodnes není až tak úplně typická holka. Jako správný starší bratr si z ní udělal
      spíš bráchu - hráli si spolu s autíčky, pařili počítačové hry a chodili do internetových kaváren. Zuzka díky tomu uměla už ve svých 4 letech poznat snad všechny
      tehdy známé značky aut. Dodnes je Peťa vášnivým fanouškem Formule 1 a závodních okruhů. Před nedávnem si s kamarádem koupil závodní Toyotu MR2 a jezdí s ní
      pravidelně na Trackdays. Manželku Zuzku poznal na Vysoké škole v Brně, vzali se v roce 2015.
    </BioContainer.CS>

    <BioContainer.EN>
      Peťa, whom no one called anything other than Mazy in his youth, is Zuzka's brother. Thanks to him she is not the typical girl to this day. As a proper older
      brother, he made her to be more like a brother - they played with toy cars, played computer games and went to internet cafes. Thanks to this, Zuzka was already
      able to recognize almost all known car brands at the age of 4. To this day, Peťa is a passionate fan of Formula 1 and racing circuits. He recently bought a racing
      Toyota MR2 with a friend and drives it regularly on Trackdays. He met his wife Zuzka at the University of Brno and they got married in 2015.
    </BioContainer.EN>
  </BioContainer>
);

export default BioPeta;
