import React from 'react';
import { Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';

import { ROUTES } from 'core/constants';
import Error404Page from './Error404Page';
import BioRoman from './Bio/BioRoman';
import BioVladka from './Bio/BioVladka';
import BioDalibor from './Bio/BioDalibor';
import BioMasa from './Bio/BioMasa';
import BioPavel from './Bio/BioPavel';
import BioBara from './Bio/BioBara';
import BioFilip from './Bio/BioFilip';
import BioPetrik from './Bio/BioPetrik';
import BioMatyas from './Bio/BioMatyas';
import BioZuzka from './Bio/BioZuzka';
import BioPeta from './Bio/BioPeta';
import BioCheddar from './Bio/BioCheddar';
import BioLubos from './Bio/BioLubos';
import BioNikol from './Bio/BioNikol';
import BioKatka from './Bio/BioKatka';
import BioSarka from './Bio/BioSarka';
import BioJindra from './Bio/BioJindra';
import BioVita from './Bio/BioVita';
import BioLucka from './Bio/BioLucka';
import BioLuky from './Bio/BioLuky';
import BioSam from './Bio/BioSam';
import BioMarek from './Bio/BioMarek';
import BioHonza from './Bio/BioHonza';
import BioAnet from './Bio/BioAnet';
import BioSabina from './Bio/BioSabina';
import BioDavid from './Bio/BioDavid';
import BioHonzaSr from './Bio/BioHonzaSr';
import BioMaruska from './Bio/BioMaruska';
import BioLibor from './Bio/BioLibor';
import BioErcaUba from './Bio/BioErcaUba';
import BioPetaVasku from './Bio/BioPetaVasku';
import BioAndy from './Bio/BioAndy';
import BioKocos from './Bio/BioKocos';
import BioKuba from './Bio/BioKuba';
import BioAlistairVeronika from './Bio/BioAlistairVeronika';
import BioStepanJana from './Bio/BioStepanJana';
import BioMartin from './Bio/BioMartin';
import BioPavelSarka from './Bio/BioPavelSarka';
import BioStepanFarhia from './Bio/BioStepanFarhia';
import BioCimbalAldamas from './Bio/BioCimbalAldamas';
import Homepage from './Homepage';
import QrCodes from './QrCodes';
import I18nContainer from '../core/I18nContainer';

const App: React.FC = () => (
  <React.Suspense fallback={<></>}>
    <I18nContainer.Provider>
      <BrowserRouter>
        <Switch>
          {/* Homepage */}
          <Route path={ROUTES.HOMEPAGE} exact={true}>
            <Homepage />
          </Route>
          <Route path={ROUTES.QR_CODES} exact={true}>
            <QrCodes />
          </Route>

          {/* Bios */}
          <Route path={ROUTES.ROMAN}><BioRoman /></Route>
          <Route path={ROUTES.VLADKA}><BioVladka /></Route>
          <Route path={ROUTES.DALIBOR}><BioDalibor /></Route>
          <Route path={ROUTES.MASA}><BioMasa /></Route>
          <Route path={ROUTES.PAVEL}><BioPavel /></Route>
          <Route path={ROUTES.BARA}><BioBara /></Route>
          <Route path={ROUTES.FILIP}><BioFilip /></Route>
          <Route path={ROUTES.PETRIK}><BioPetrik /></Route>
          <Route path={ROUTES.MATYAS}><BioMatyas /></Route>
          <Route path={ROUTES.ZUZKA}><BioZuzka /></Route>
          <Route path={ROUTES.PETA_MAZAC}><BioPeta /></Route>
          <Route path={ROUTES.CHEDDAR}><BioCheddar /></Route>
          <Route path={ROUTES.LUBOS}><BioLubos /></Route>
          <Route path={ROUTES.NIKOL}><BioNikol /></Route>
          <Route path={ROUTES.KATKA}><BioKatka /></Route>
          <Route path={ROUTES.SARKA}><BioSarka /></Route>
          <Route path={ROUTES.JINDRA}><BioJindra /></Route>
          <Route path={ROUTES.VITA}><BioVita /></Route>
          <Route path={ROUTES.LUCKA}><BioLucka /></Route>
          <Route path={ROUTES.LUKY}><BioLuky /></Route>
          <Route path={ROUTES.SAM}><BioSam /></Route>
          <Route path={ROUTES.MAREK}><BioMarek /></Route>
          <Route path={ROUTES.HONZA}><BioHonza /></Route>
          <Route path={ROUTES.ANET}><BioAnet /></Route>
          <Route path={ROUTES.SABINA}><BioSabina /></Route>
          <Route path={ROUTES.DAVID}><BioDavid /></Route>
          <Route path={ROUTES.HONZA_TATA}><BioHonzaSr /></Route>
          <Route path={ROUTES.MARUSKA}><BioMaruska /></Route>
          <Route path={ROUTES.LIBOR}><BioLibor /></Route>
          <Route path={ROUTES.ERCA_UBA}><BioErcaUba /></Route>
          <Route path={ROUTES.PETA_VASKU}><BioPetaVasku /></Route>
          <Route path={ROUTES.ANDY}><BioAndy /></Route>
          <Route path={ROUTES.KOCOS}><BioKocos /></Route>
          <Route path={ROUTES.KUBA}><BioKuba /></Route>
          <Route path={ROUTES.ALISTAIR_VERONIKA}><BioAlistairVeronika /></Route>
          <Route path={ROUTES.STEPAN_JANA}><BioStepanJana /></Route>
          <Route path={ROUTES.MARTIN}><BioMartin /></Route>
          <Route path={ROUTES.PAVEL_SARKA}><BioPavelSarka /></Route>
          <Route path={ROUTES.STEPAN_FARHIA}><BioStepanFarhia /></Route>
          <Route path={ROUTES.CIMBAL_ALDAMAS}><BioCimbalAldamas /></Route>

          {/* Errors */}
          <Route path="*">
            <Error404Page />
          </Route>
        </Switch>
      </BrowserRouter>
    </I18nContainer.Provider>
  </React.Suspense>
);

export default App;
