import React from 'react';
import BioContainer from './BioContainer';

const BioMaruska: React.FC = () => (
  <BioContainer
    name="Maruška"
    photoName="libor_maruska.jpg"
  >
    <BioContainer.CS>
      Michalova máma Maruška je člověk s nekonečnou trpělivostí. Vychovávat Honzu s Michalem nebyla žádná procházka růžovým sadem. Aby toho nebylo málo, tak se navíc
      starala o kopu cizích dětí (nebo jak rádá říká “šmejdů”) jako učitelka na gymnáziu. Hříčkou osudu učila nejen Michala, ale také Zuzku, jejího bráchu i mámu Vlaďku.
      Dnes už si užívá zaslouženého důchodu a pokud zrovna nevaří nebo nepeče, tak jezdí venku na kole. Mezi její oblíbené sporty patří tenis. Je taky expertem v
      zahrádkaření bez zahrádky.
    </BioContainer.CS>

    <BioContainer.EN>
      Michal's mother, Maruška, is a person with infinite patience. Raising Honza with Michal was no walk in a park. To make matters worse, she also took care of a
      bunch of other children (or as she likes to say "scumbags") as a high school teacher. As fate would have it she taught not only to Michal, but also to Zuzka, her
      brother Péťa and mother Vlaďka. Today she is enjoying her well-deserved retirement. If she isn’t cooking or baking, she is riding her bike somewhere outside.
      Tennis is her favorite sport. She is also an expert in gardening without a garden.
    </BioContainer.EN>
  </BioContainer>
);

export default BioMaruska;
