import React from 'react';
import BioContainer from './BioContainer';

const BioDavid: React.FC = () => (
  <BioContainer
    name="David"
    photoName="david.jpg"
  >
    <BioContainer.CS>
      David je syn Honzy a Sabiny. Svému tátovi jakoby z oka vypadl. Většinu času je doma v Chorvatsku se svou mámou Sabinou, ale často ho Honza přiveze k nám do Rožnova
      za babičkou Maruškou. Jak takhle cestuje, tak plynule přepíná mezi chorvatštinou, češtinou a angličtinou. Příští rok ho čeká první rok ve škole.
    </BioContainer.CS>

    <BioContainer.EN>
      David is the son of Honza and Sabina. He takes after his father and looks like his younger clone. Most of the time he is at home in Croatia with his mother
      Sabina, but Honza often brings him to our place in Rožnov to see his grandmother Maruška. Because of all this traveling, he can switch between Croatian, Czech and
      English fluently. Next year will be his first year in school.
    </BioContainer.EN>
  </BioContainer>
);

export default BioDavid;
