/** Application routes */
export const ROUTES = {
  // Main navigation
  HOMEPAGE: '/',
  QR_CODES: '/qr-codes',

  // Bios
  ROMAN: '/roman',
  VLADKA: '/vladka',
  DALIBOR: '/dalibor',
  MASA: '/masa',
  PAVEL: '/pavel',
  BARA: '/bara',
  FILIP: '/filip',
  PETRIK: '/petrik',
  MATYAS: '/matyas',
  ZUZKA: '/zuzka',
  PETA_MAZAC: '/peta-mazac',
  CHEDDAR: '/cheddar',
  LUBOS: '/lubos',
  NIKOL: '/nikol',
  KATKA: '/katka',
  SARKA: '/sarka',
  JINDRA: '/jindra',
  VITA: '/vita',
  LUCKA: '/lucka',
  LUKY: '/luky',
  SAM: '/sam',
  MAREK: '/marek',
  HONZA: '/honza',
  ANET: '/anet',
  SABINA: '/sabina',
  DAVID: '/david',
  HONZA_TATA: '/honza-tata',
  MARUSKA: '/maruska',
  LIBOR: '/libor',
  ERCA_UBA: '/erca-uba',
  PETA_VASKU: '/peta-vasku',
  ANDY: '/andy',
  KOCOS: '/kocos',
  KUBA: '/kuba',
  ALISTAIR_VERONIKA: '/alistair-veronika',
  STEPAN_JANA: '/stepan-jana',
  MARTIN: '/martin',
  PAVEL_SARKA: '/pavel-sarka',
  STEPAN_FARHIA: '/stepan-farhia',
  CIMBAL_ALDAMAS: '/cimbal-aldamas',
};
