import React from 'react';
import BioContainer from './BioContainer';

const BioErikaUba: React.FC = () => (
  <BioContainer
    name="Erča + Uba"
    photoName="ersi_uba.jpg"
  >
    <BioContainer.CS>
      Ersi vede rožnovskou taneční skupinu Heart2Beat & Static Breakers. S Michalem se zná už skoro dvacet let. Poprvé se potkali přes společné kamarády v rožnovské
      Brasserii, kde Ersi pracovala jako číšnice. Postupem času jí také Michal pomáhal s mixováním hudby do jejích tanečních choreografií a pomáhal vymýšlet jméno pro
      její novou taneční skupinu - Heart2Beat. Zuzka o Ersi natáčela biografický dokument jako bakalářskou práci. S manželem Ubou mají dvě holčičky. Uba je profesionální
      sportovní fotograf, fotil dokonce i na olympiádách. Je také vášnivý turista a hiker. Se Zuzkou se znají od doby natáčení dokumentu o Ersi, kdy jí trpělivě pomáhal.
    </BioContainer.CS>

    <BioContainer.EN>
      Erika leads the Rožnov dance group Heart2Beat & Static Breakers. She has known Michal for almost twenty years. They met for the first time through mutual friends
      in the Roznov Brasserie, where Erika worked as a waitress. Over time, Michal also helped her mix music for her dance choreography and helped come up with a name
      for her new dance group - Heart2Beat. Zuzka filmed a biographical documentary about Erika as a school assignment. She and her husband Ubald have two children. Uba
      is a professional sports photographer whose work also includes taking photos at the Olympics. He is also an avid hiker. They have known Zuzka since the filming of
      the documentary with Erika, and he also helped her with her bachelor's thesis.
    </BioContainer.EN>
  </BioContainer>
);

export default BioErikaUba;
