import React from 'react';
import BioContainer from './BioContainer';

const BioPetrik: React.FC = () => (
  <BioContainer
    name="Petřík"
    photoName="mazaci_mladi.jpg"
  >
    <BioContainer.CS>
      Petřík je oblíbeným synovcem Zuzky, vysmátý předškolák, jehož prupovídky snad jednou vyjdou knižně. Jeho dlouhé řasy budou zanedlouho dostávat spolužačky do kolen a
      letošní podzim ukázal, že je absolutním přeborníkem v houbaření.
    </BioContainer.CS>

    <BioContainer.EN>
      Petřík is Zuzka's favorite nephew, a hilarious preschooler whose short statemets will hopefully one day be published as a book. His long eyelashes will soon bring
      his classmates to their knees, and this autumn he showed that he is an absolute champion in mushroom hunting.
    </BioContainer.EN>
  </BioContainer>
);

export default BioPetrik;
