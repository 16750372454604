import React from 'react';
import BioContainer from './BioContainer';

const BioMatyas: React.FC = () => (
  <BioContainer
    name="Matyáš"
    photoName="mazaci_mladi.jpg"
  >
    <BioContainer.CS>
      Nejmladší účastník svatby a dvojník staršího Petříka. Kluk, který se narodil s více vlasy než má běžně dospělý člověk a díky kterému je nyní klan Mazáčů kompletní.
    </BioContainer.CS>

    <BioContainer.EN>
      The youngest participant in the wedding and a younger doppleganger of Petřík. A boy who was born with more hair than a normal adult and who now makes the Mazáč
      clan complete.
    </BioContainer.EN>
  </BioContainer>
);

export default BioMatyas;
