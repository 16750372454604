import React from 'react';
import BioContainer from './BioContainer';

const BioCimbalAldamas: React.FC = () => (
  <BioContainer
    name="Cimbálová muzika Aldamáš"
    photoName="cimbal_aldamas.jpg"
  >
    <BioContainer.CS>
    Z cimbálovky Aldamáš zná Zuzka hned dva členy - se Štěpánem (druhý zprava) chodila na základní školu a s Filipem (vpravo) chodila na stejnou střední a do flétnového souboru. Z flétny ale Filip jak vidno přesídlil i na řadu dalších hudebních nástrojů. Cimbálovku Aldamáš si Zuzka taktéž oblíbila při natáčení svateb v okolí, kde se už několikrát pracovně potkali. K tanci a poslechu Vám v průběhu odpoledne zahrají tradiční lidové písně, ale i známé hity.
    </BioContainer.CS>

    <BioContainer.EN>
    Zuzka knows two members of the Aldamáš dulcimer band (“cimbálovka”). She went to elementary school with Štěpán (second from right) and to the same high school
    and flute class with Filip (right). But as you can see, Filip moved from the flute to a number of other musical instruments. Zuzka also took a liking to the
    Aldamáš dulcimer when filming weddings in the area, where they had already met several times at work. During the afternoon, they will play traditional folk songs
    as well as well-known hits for you to dance and listen to.
    </BioContainer.EN>
  </BioContainer>
);

export default BioCimbalAldamas;
