import React from 'react';
import BioContainer from './BioContainer';

const BioVita: React.FC = () => (
  <BioContainer
    name="Víťa"
    photoName="vita.jpg"
  >
    <BioContainer.CS>
      Víťa je Zuzčin spolužák z vysoké a nadaný kameraman. Už když viděla jeho první svatební videa někdy v roce 2016, věděla, že pokud bude mít někdy svatbu, bude ji
      chtít natočit od něj. Kromě filmařiny je to nadšený cestovatel, probádal např. Kubu, Kostariku, USA, Zanzibar, Dubaj, Rhodos, Argentinu, Mallorku, Sardínii a
      většinu kontinentální Evropy. Tím ale rozhodně nekončí. Víťa pochází z Horní Lhoty, dříve se aktivně věnoval freestyle fotbalu a letos v září se šťastně oženil.
    </BioContainer.CS>

    <BioContainer.EN>
      Víťa is Zuzka's classmate from college and a talented cameraman. When she saw his first wedding videos sometime in 2016, she knew that if she ever had a wedding,
      she would want him to film it. In addition to filmmaking, he is an enthusiastic traveler having explored, for example, Cuba, Costa Rica, USA, Zanzibar, Dubai,
      Rhodes, Argentina, Mallorca, Sardinia and most of continental Europe. But it certainly does not end there. Víťa comes from Horní Lhota, he used to be actively
      involved in freestyle football and he got happily married this September.
    </BioContainer.EN>
  </BioContainer>
);

export default BioVita;
