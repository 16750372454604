import React from 'react';
import BioContainer from './BioContainer';

const BioLucka: React.FC = () => (
  <BioContainer
    name="Lucka"
    photoName="lucka.jpg"
  >
    <BioContainer.CS>
      Na Lucku dostali novomanželé doporučení od svědkyně Máši, která ji také původně chtěla jako fotografku na svou svatbu, ale měla tehdy plno. Lucka je z nedalekého
      Frýdku-Místku, takže to na Rekovicích zná a dokonce zná i kameramana Víťu a oddávajícího Romana, se kterým se letos sešli už na několikáté svatbě. Když Zuzka viděla
      Lucčiny fotky jedné zimní svatby na Rekovicích - bylo vymalováno.
    </BioContainer.CS>

    <BioContainer.EN>
      The newlyweds received a recommendation for Lucka from the maid of honor Máša, who also originally wanted her as a photographer for her wedding, but Lucka was
      not available at that time. She is from nearby Frýdek-Místek and knows Rekovice very well and even knows the cameraman Víťa and the priest Roman, whom she met for
      the umpteenth wedding this year. When Zuzka saw Lucka's photos of a winter wedding in Rekovice - there was no other choice.
    </BioContainer.EN>
  </BioContainer>
);

export default BioLucka;
