import React from 'react';
import BioContainer from './BioContainer';

const BioKuba: React.FC = () => (
  <BioContainer
    name="Kuba"
    photoName="kubik_katerina_apolenka.jpg"
  >
    <BioContainer.CS>
      Rodák z Třince se se Michalem se znají už přes deset let od z práce v ČSOB a od té doby jsou dobrými kamarády. Kromě toho, že je Kuba vynikajícím programátorem, je
      taky znalec dobré whiskey. Za zmínku taktéž stojí jeho záliba v etickém hackingu (včetně certifikací na IT systémovou bezpečnost) a páčení zámků (lockpicking). Před
      měsícem se účastnil českého mistrovství v lockpickingu a dostal se do finále, kde skončil na skvělém sedmém místě mezi profesionálními zámečníky. Se svou snoubenkou
      Kateřinou mají společně dceru Apolenku.
    </BioContainer.CS>

    <BioContainer.EN>
      A native of Třinec, he and Michal have known each other for over ten years since working at ČSOB and have been good friends ever since. Besides being an
      excellent programmer, Kuba is also a connoisseur of good whiskey. Also worth mentioning is his interest in ethical hacking (including IT system security
      certifications) and lockpicking. A month ago, he participated in the Czech lockpicking championship and reached the finals, where he finished in a great seventh
      place among professional locksmiths. Together with his fiancee Kateřina, they have a daughter Apolenka.
    </BioContainer.EN>
  </BioContainer>
);

export default BioKuba;
