import React from 'react';
import BioContainer from './BioContainer';

const BioPavelSarka: React.FC = () => (
  <BioContainer
    name="Pavel + Šárka"
    photoName="pavel_sarka.jpeg"
  >
    <BioContainer.CS>
      Pavla, podobně jako Martina, zná Michal z hraní her v rožnovských internetových kavárnách. Společně se dokonce během studií chvíli věnovali hraní Counter-Strike v
      druhé české lize v klanu e-Rencontre. Když Michala zavedla práce zpět do Prahy, nabídl mu Pavel střechu nad hlavou ve svém bytě, kde nakonec společně s jeho
      přítelkyní Šárkou a jejich pejskem Žerykem strávil přes pět let života. Ze své pozice technické podpory v Securitas ze za posledních deset let vypracoval až na IT
      manažera mezinárodních projektů. Je to velký nadšenec do počítačů a technologií obecně.
    </BioContainer.CS>

    <BioContainer.EN>
      Pavel, like Martin, knows Michal from playing games in Internet cafes in Rožnov. Together, during their studies, they even spent some time playing Counter-Strike
      in the second Czech league in the e-Rencontre clan. When work took Michal back to Prague, Pavel offered him a place to stay in his apartment, where he eventually
      spent over five years of his life together with Pavel’s girlfriend Šárka and their dog Žeryk. Pavel worked his way from his technical support position at
      Securitas up to IT manager of Securitas international projects over the past ten years. He is a big computer and technology enthusiast in general.
    </BioContainer.EN>
  </BioContainer>
);

export default BioPavelSarka;
