import React from 'react';
import BioContainer from './BioContainer';

const BioLibor: React.FC = () => (
  <BioContainer
    name="Libor"
    photoName="libor_maruska.jpg"
  >
    <BioContainer.CS>
      Libor je dlouhodobým partnerem Michalovy mámy Marušky. Jejich děti vyrůstaly společně a taky spolu všichni často cestovali na dovolené. Libor miluje tenis, jízdu na
      kole (mimo jiné jako dopravní prostředek z a do krčmy :) ) a v zimě lyžování. Stejně jako Maruška si také užívá důchodu a vnoučat.
    </BioContainer.CS>

    <BioContainer.EN>
      Libor is a long-term partner of Michal's mother Maruška. Their children grew up together and they all often traveled together on vacation. Libor loves tennis,
      cycling (among other things as a means of transport to and from the pub :) ) and skiing in the winter. Like Maruška, he is also enjoying his retirement and
      grandchildren.
    </BioContainer.EN>
  </BioContainer>
);

export default BioLibor;
