import React from 'react';
import BioContainer from './BioContainer';

const BioPetaVasku: React.FC = () => (
  <BioContainer
    name="Peťa V."
    photoName="peta_andy.jpg"
  >
    <BioContainer.CS>
      Peťa je další z řady Zuzčiných spolužáků z rožnovského gymplu, který momentálně studuje doktorský program na Divadelní fakultě JAMU v Brně. Nejlépe se představil
      sám už v roce 2017: „Byl jsem odkojen v malebných panelácích maloměsta s výhledem na Radhošť. Nestal jsem se politologem, ekonomem ani novinářem. Nakonec nejsem ani
      kytarovým virtuosem, i když úspěšně hraji v několika kapelách, z nichž valná většina zatím zůstává fiktivní nebo imaginární. Rád se ztrácím v zemích, ve kterých
      nemluví mým jazykem. Nemám své nejoblíbenější filmy, knihy, skladatele, básníky, pokrmy, rodinné příslušníky či ponožky, protože doceňuji krásu různosti.“
    </BioContainer.CS>

    <BioContainer.EN>
      Peťa is another of Zuzka's classmates from the Rožnov high school, who is currently studying a doctoral program at the Theater Faculty of JAMU in Brno. He best
      introduced himself in 2017: "I was weaned in the picturesque apartment blocks of a small town with a view of Radhošť. I did not become a political scientist,
      economist or journalist. In the end, I'm not even a guitar virtuoso, although I do play in several bands, the vast majority of which remain fictitious or
      imaginary. I like to get lost in countries where they don't speak my language. I don't have favorite movies, books, composers, poets, foods, family members or
      socks because I appreciate the beauty of diversity.”
    </BioContainer.EN>
  </BioContainer>
);

export default BioPetaVasku;
