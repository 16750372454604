import React from 'react';
import BioContainer from './BioContainer';

const BioStepanFarhia: React.FC = () => (
  <BioContainer
    name="Štěpán + Farhia"
    photoName="stepan_farhia.jpg"
  >
    <BioContainer.CS>
      Štěpán je původem z nedaleké Dolní Bečvy, takže Valach jako poleno. Se Zuzkou se náhodou potkali v Praze na Žižkově, kde spolu sdílejí kanceláře. Štěpán je učitelem
      na kytaru a do toho rozjíždí business s akustickými panely. Jednoho dne se přiřítil se zprávou, že zná super zpěvačku Farhiu a že spolu začínají hrát. Takže když
      Michal v březnu poklekl, už bylo jasné, kdo jim na svatbě bude hrát.
    </BioContainer.CS>

    <BioContainer.EN>
      Štěpán is originally from nearby town of Dolní Bečva, so he is as Walachian as it gets. He met Zuzka by chance in Prague, Žižkov, where they share offices.
      Štěpán is a guitar teacher and is also starting a business with acoustic panels. One day he rushed in with the news that he knew the super singer Farhia and that
      they were going to play together. So when Michal got down on one knee in March, it was already clear who would play at our wedding.
    </BioContainer.EN>
  </BioContainer>
);

export default BioStepanFarhia;
