import React from 'react';
import BioContainer from './BioContainer';

const BioRoman: React.FC = () => (
  <BioContainer
    name="Roman"
    photoName="roman.jpg"
  >
    <BioContainer.CS>
      Romana si Zuzka vyhlídla už někdy v roce 2017, když se jako dva Rožnováci náhodně setkali na svatbě v dalekém Středočeském kraji - on oddával, ona natáčela. Už
      tehdy jí bylo jasné, že jestli se někdy vdá, musí u toho být i Roman. Jenže mezitím stihla zapomenout jeho jméno i církev, pro kterou slouží. Po náročném pátrání se
      jí nakonec podařilo se s Romanem spojit a on souhlasil, že je oddá v prosinci venku, i když bude dle jeho slov “kosina jak sviňa”. Roman je původem z Havířova, k
      víře se dostal až v dospělosti. Pracuje s lidmi v krizi, přednáší na školách a je pastorem Apoštolské církve.
    </BioContainer.CS>

    <BioContainer.EN>
      Zuzka kept an eye out for Roman since 2017 when they first met at a wedding in Central Bohemia, far away from their hometown. Roman as a priest and Zuzka
      as a wedding filmmaker. It was there and then that she realized that if she ever was to marry Roman had to be the priest. But since then managed to forget
      not only his name but also the church he serves. After an exhausting search she finally managed to get into contact with him and he agreed to marry them
      in December even though it will be “cold as hell”. Roman is from Havířov. He joined the Apostle church later on in his life as an adult. He often works
      with people going through a life crisis and is an Apostle church pastor.
    </BioContainer.EN>
  </BioContainer>
);

export default BioRoman;
