import React from 'react';
import BioContainer from './BioContainer';

const BioLuky: React.FC = () => (
  <BioContainer
    name="Luky"
    photoName="luky.jpg"
  >
    <BioContainer.CS>
      Luky je Zuzčin nejoblíbenější (a také jediný) bratranec a střízlivý řidič této svatby. Zuzka s Lukym mají úplně stejný smysl pro humor a zažili spolu nekonečné
      záchvaty smíchu. Luky taktéž jako první ukázal Zuzce kouzlo seriálu Červený trpaslík, který od té doby viděla nesčetněkrát. A právě díky perfektní znalosti tohoto
      seriálu pak o několik let později okouzlila svého nynějšího novomanžela. Luky je už několik let šťastně ženatý a má dvě děti.
    </BioContainer.CS>

    <BioContainer.EN>
      Luky is Zuzka's favorite (and only) cousin and the dedicated driver of this wedding. Zuzka and Luky have exactly the same sense of humor and they had endless
      bouts of laughter together. Luky was also the first to show Zuzka the magic of the Red Dwarf series, which she has seen countless times since then. And thanks to
      her perfect knowledge of this series, she charmed her new husband a few years later. Luky has been happily married for several years and has two children.
    </BioContainer.EN>
  </BioContainer>
);

export default BioLuky;
