import React from 'react';
import BioContainer from './BioContainer';

const BioKatka: React.FC = () => (
  <BioContainer
    name="Káťa"
    photoName="katka.jpg"
  >
    <BioContainer.CS>
      Káťa je další členka a spolumajitelka Wolfstreet crew, třebíčská rodačka z umělecké rodiny. Má dva starší bráchy, takže je podobně jako Zuzka sama v mnoha směrech
      tak trochu kluk. Káťa je studentkou Arts managementu na VŠE, umí spojit business s uměním a je to specialistka na sociální sítě. Talentovaná žena, která už během
      studií nelení a plně rozjíždí svou kariérní hru. Ráda a často cestuje, např. hned po této svatbě letí s přítelem na Bali.
    </BioContainer.CS>

    <BioContainer.EN>
      Káťa is another member and co-owner of the Wolfstreet crew, a native of Třebíč from an artistic family. She has two older brothers, so, like Zuzka herself, she
      is a bit of a boy in many ways. Káťa is a student of Arts Management at the University of Economics, knows how to combine business with art and is a specialist in
      social networking. A talented woman who is no longer lazing around during her studies and instead fully kicks off her career game. She likes to travel often, e.g.
      immediately after this wedding she flies to Bali with her boyfriend.
    </BioContainer.EN>
  </BioContainer>
);

export default BioKatka;
