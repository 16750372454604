import React from 'react';
import BioContainer from './BioContainer';

const BioSarka: React.FC = () => (
  <BioContainer
    name="Šárka"
    photoName="sarka_jindra.jpg"
  >
    <BioContainer.CS>
      Cesta Šárky a Zuzky se protnuly na vysoké škole. Zuzka se dostala na studium do Opavy, kde ale vůbec nikoho neznala. Přidala se do Facebookové skupiny, kde studenti
      hledají spolubydlení a narazila na příspěvek Šárky, která hledala někoho do společného pokoje. Zuzka se s ní spojila po telefonu a dohodly se. Z nesmělého oťukávání
      se za pouhý týden stalo nekonečné vykecávání, půlnoční stěhování nábytku, společné marodění, divoké párty a hlavně pravidelné sledování Věřte nevěřte s mísou
      polárkového dortu. Šárka se nedá přeslechnout - její hlasitý smích je až nakažlivý.
    </BioContainer.CS>

    <BioContainer.EN>
      Šárka and Zuzka's paths crossed at university. Zuzka got to study in Opava, but she didn't know anyone at all. She joined a Facebook group where students look
      for roommates and came across a post by Šárka, who was looking for someone to share a room with. Zuzka contacted her by phone and they agreed. In just a week, the
      timid groping turned into endless chatter, midnight moving of furniture, wild parties and, above all, regular watching of “Believe it or Not” episodes with a bowl
      of ice-cream. You cannot miss Šárka - her loud laugh is contagious.
    </BioContainer.EN>
  </BioContainer>
);

export default BioSarka;
